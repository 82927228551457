import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { SmartLink } from '../../SmartLink';
import { Image } from '../../Image';
import { t } from '../../../domain/services/configService';
import { ngartImgPlayButton } from '../../Styles/articleImage';
import { cardTagSponsored } from '../../Styles/card';
import { getMainImages } from '../../../domain/utils/contentUtils';

export const CarouselCard = ({
  data,
  fontColor,
  aspect,
  transition,
  cardClass,
  cardRel,
  isAboveTheFold
}) => {
  const imageWidth = 340;
  const styles = {
    cardSubtitle: css`
      ${theme.font.family('regularWeb')};
      font-size: ${theme.font.size('s')};
      font-weight: ${theme.font.weight('base')};
      letter-spacing: ${theme.letterSpacing('s')};
      margin-bottom: ${theme.spacing('xs2')};
      ${theme.mq.small_desktop} {
        font-size: ${theme.font.size('base')};
      }
      text-align: left;
    `,
    cardStackCard: css`
      white-space: initial;
      vertical-align: top;
      position: relative;
      display: inline-block;
      padding: ${theme.spacing('xs2')};
      width: 50%;
      ${theme.mq.tablet} {
        width: 25%;
      }
      ${theme.mq.small_desktop} {
        padding: ${theme.spacing('xs')};
      }
      ${transition
        ? `img {
        transform-origin: 65% 75%;
        transition: transform 1s, filter 0.5s ease-out;
        &:hover {
          transform: scale(1.2);
        }
      }`
        : null}
    `,
    sponsoredContent: css`
      position: absolute;
      left: 15%;
      right: 15%;
      bottom: 35%;
      text-transform: uppercase;
    `,
    imageContainer: css`
      position: relative;
    `
  };

  const { title, subtitle } = data;
  const image =
    data.cardThumbnail?.entity ||
    data.cardThumbnail ||
    (data.carouselRefCard?.entity
      ? getMainImages(data.carouselRefCard.entity)[0]
      : null);
  if (image) {
    image.focalPoint = data.focalPoint || image.focalPoint;
  }
  if (image.entity) {
    image.entity.focalPoint = data.focalPoint || image.entity?.focalPoint;
  }

  const link =
    data.carouselRefCard?.entity?.link?.url?.path ||
    data.carouselRefCard?.entity?.url?.path ||
    data.link?.url?.path ||
    data.link?.uri;
  const isSponsoredContent =
    data.carouselRefCard?.entity?.sponsoredTextCard &&
    data.carouselRefCard?.entity?.branded &&
    data.showExternalSponsorLabel;
  const isVideoArticle =
    data.carouselRefCard?.entity?.mediaType === 'shortform';

  const cardStyles = {
    cardTitle: css`
      color: ${fontColor};
      ${theme.font.family('regularWeb')};
      font-size: ${theme.font.size('l')};
      font-weight: ${theme.font.weight('l')};
      letter-spacing: ${theme.letterSpacing('s')};
      margin: ${theme.spacing.parse('$s 0 auto')};
      ${theme.mq.small_desktop} {
        font-size: ${theme.font.size('xl')};
      }
      text-align: left;
    `,
    videoPlayButton: css`
      display: ${isVideoArticle ? 'inline-block' : 'none'};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      height: 70px;
      width: 70px;
      &:after {
        border-bottom: 13px solid rgb(0 0 0 / 0%);
        border-top: 13px solid rgb(0 0 0 / 0%);
        border-left: 21px solid rgb(255 204 0);
      }
    `
  };

  const card = (
    <>
      <div css={styles.imageContainer}>
        {image ? (
          <Image
            entity={image.entity || image}
            maxWidths={{
              mobile: imageWidth,
              tablet: imageWidth,
              small_desktop: imageWidth,
              large_desktop: imageWidth
            }}
            aspect={aspect}
            loading={isAboveTheFold ? 'eager' : 'lazy'}
          />
        ) : null}
        <div
          className="ngart-img__play-button"
          css={[
            cardStyles.videoPlayButton,
            ngartImgPlayButton,
            css`
              left: 50%;
            `
          ]}
        />
      </div>
      {isSponsoredContent && (
        <div
          className="card__tag--sponsored"
          css={[styles.sponsoredContent, cardTagSponsored]}
        >
          {`${t('Paid content')} `}
        </div>
      )}
      {title && <div css={cardStyles.cardTitle}>{title}</div>}
    </>
  );

  return (
    <div css={styles.cardStackCard}>
      {link ? (
        <SmartLink
          to={link}
          target={data.openLinkInNewTab ? '_blank' : null}
          title={data.carouselRefCard?.entity?.promoTitle || data.link?.title}
          className={cardClass}
          rel={cardRel}
        >
          {card}
        </SmartLink>
      ) : (
        card
      )}

      {subtitle && <div css={styles.cardSubtitle}>{subtitle}</div>}
    </div>
  );
};

CarouselCard.defaultProps = {
  aspect: '1x1',
  transition: true,
  cardClass: undefined,
  cardRel: undefined
};

CarouselCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  fontColor: PropTypes.string.isRequired,
  aspect: PropTypes.string,
  transition: PropTypes.bool,
  cardClass: PropTypes.string,
  cardRel: PropTypes.string,
  isAboveTheFold: PropTypes.bool.isRequired
};
